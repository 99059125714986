import styled from "styled-components";

interface FooterItemProp {
  width?: string;
  swidth?: string;
  display?: string;
  smt?: string;
}

export const FooterBox = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 7% 0px;
  background-image: linear-gradient(0.56deg, #01c4e7 -74.45%, #314cb6 91.53%);
  position: relative;
  margin-top: 220px;

  .icon {
    position: absolute;
    left: 47%;
    top: -70px;
  }

  @media screen and (max-width: 650px) {
    .icon {
      left: 42%;
      width: 75px;
      top: -45px;
    }

    margin-top: 120px;
  }
`;

export const FooterGallery = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0px auto;

  overflow: hidden;
  width: 100%;
  position: relative;

  .gallery {
    /* width: 10%; */
    height: auto;
    margin-top: 20px;
  }

  .gallery-content {
    position: absolute;
    width: 100%;
    background: rgba(44, 91, 189, 0.51);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .g-text {
    font-size: 70px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    width: 100%;
    max-width: 1000px;
  }

  .box {
    display: flex;
    align-items: center;
    margin-top: 30px;
    grid-column-gap: 10px;
  }

  .error {
    color: var(--billonErrorText);
    font-size: 14px;
    margin-top: 5px;
  }

  .g-btn {
    /* margin-top: 30px; */
    /* width: 120px; */
  }

  @media screen and (max-width: 850px) {
    grid-column-gap: 15px;

    br {
      display: none;
    }

    .g-text {
      font-size: 45px;
    }

    .gallery-content {
      padding: 0px 5%;
    }
  }

  @media screen and (max-width: 650px) {
    .g-btn {
      width: 100%;
      margin-top: 10px;
    }

    .g-text {
      font-size: 36px;
    }

    .box {
      width: 100%;
      flex-direction: column;
    }

    .input {
      width: 100%;
    }
  }

  @media screen and (min-width: 1450px) {
    grid-column-gap: 15px;
  }
`;

export const FooterParent = styled.div`
  max-width: 1600px;
  width: 100%;
  padding: 0px 5%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 60px;

  @media screen and (max-width: 850px) {
    flex-wrap: wrap;
  }
`;

export const FooterItemList = styled.ul<FooterItemProp>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .cursor {
    cursor: pointer;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-top: 5px;
  }

  .text {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    line-height: 25px;
  }

  .list-box {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    grid-row-gap: 15px;
  }

  .list-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #fff;
    margin: 10px 0px;
  }

  .link-text {
    width: 80%;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    position: relative;

    &:hover::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 50px;

      height: 2px;
      background-color: #fff;
      transition: width 0.5s;
    }
  }

  .socials-box {
    margin-top: 30px;
    display: flex;
    grid-column-gap: 15px;
  }

  .social-item {
    width: 30px;
    height: 30px;
    background: #eaedf1;
    border-radius: 5px;
  }

  .small-logo {
    display: block;
    width: 150px;
    margin-bottom: 10px;
  }

  .logo {
    width: 150px;
    height: auto;
  }

  .socials-box-mobile {
    display: none;
    justify-content: center;
    grid-column-gap: 10px;
    margin-top: 20px;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 750px) {
    width: 100%;

    .link-text {
      width: 100%;
      text-align: center;
    }

    .list-text {
      width: 60%;
      text-align: center;
    }

    .text {
      width: 60%;
      text-align: center;
    }

    .small-logo {
      display: block;
    }

    .socials-box {
      display: none;
    }

    .socials-box-mobile {
      display: flex;
    }
  }
`;
