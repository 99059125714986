import React from "react";
import Hero from "../../components/Hero/hero";
import Join from "../../components/Join/join";
import Footer from "../../components/Footer/footer";

const Home = () => {
  return (
    <>
      <Hero />
      <Join />
      <Footer />
    </>
  );
};

export default Home;
