import React from "react";
import { JoinBox, JoinTitle, JoinListBox } from "./join.styles";
import J1 from "../../assets/j1.png";
import J2 from "../../assets/j2.png";
import J3 from "../../assets/j3.png";
import J4 from "../../assets/j4.png";

const Join: React.FC = () => {
  return (
    <JoinBox>
      <JoinTitle>
        Why should you join our <span>waitlist?</span>
      </JoinTitle>

      <JoinListBox>
        <img src={J1} alt="" className="join-img" />

        <div className="join-box">
          <h4 className="join-title">
            Multi-user <br /> Account Solution
          </h4>
          <p className="join-text">
            Effortlessly add, oversee, and efficiently manage user access to
            your account to ensure a seamless bill payment experience.
          </p>
        </div>
      </JoinListBox>

      <JoinListBox flexDir="row-reverse">
        <img src={J2} alt="" className="join-img" />

        <div className="join-box">
          <h4 className="join-title">Pay and Earn</h4>
          <p className="join-text">
            Collect “gemstones” as you pay on BillOn and win fabulous prizes,
            discounts, and cashbacks.
          </p>
        </div>
      </JoinListBox>

      <JoinListBox>
        <img src={J3} alt="" className="join-img" />

        <div className="join-box">
          <h4 className="join-title">
            Low <br /> Transaction Charges
          </h4>
          <p className="join-text">
            Discover a seamless and efficient bill payment experience with
            ultra-low transaction charges – your gateway to cost-effective
            financial transactions.
          </p>
        </div>
      </JoinListBox>

      <JoinListBox flexDir="row-reverse">
        <img src={J4} alt="" className="join-img" />

        <div className="join-box">
          <h4 className="join-title">
            Robust <br /> transaction reporting
          </h4>
          <p className="join-text">
            Experience the transformative potential of our robust transaction
            reporting service, where data is seamlessly converted into
            actionable insights, illuminating your journey towards success and
            revolutionizing the way you make decisions and optimize performance.
          </p>
        </div>
      </JoinListBox>
    </JoinBox>
  );
};

export default Join;
