import styled from "styled-components";

interface Prop {
  flexDir?: string;
  maxWidth?: string;
}

export const JoinBox = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;

  @media screen and (max-width: 900px) {
    padding: 0px 5%;
  }
`;

export const JoinTitle = styled.h2`
  color: #15151d;
  text-align: center;
  font-family: Switzer;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  margin: 0px auto;
  width: 100%;
  max-width: 480px;
  margin-bottom: 30px;
  margin-top: 130px;

  span {
    color: #2e70ff;
  }

  @media screen and (max-width: 700px) {
    font-size: 35px;
    margin-top: 40px;
  }
`;

export const JoinListBox = styled.div<Prop>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ flexDir }) => flexDir || "row"};
  align-items: center;
  grid-column-gap: 9%;
  width: 100%;
  margin-top: 70px;

  .join-box {
    padding: 0px 25px;
  }

  .join-img {
    max-width: 551px;
    width: 100%;
    object-fit: contain;
    height: auto;
  }

  .join-title {
    color: #15151d;
    font-family: Switzer;
    font-size: 53px;
    font-style: normal;
    font-weight: 400;
    /* max-width: 440px; */
    width: 100%;
    line-height: 70px;
  }

  .join-text {
    /* max-width: 383px; */
    width: 100%;
    color: #161718;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    line-height: 35px;
    margin-top: 20px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 76px;

    br {
      display: none;
    }

    .join-img {
      /* max-width: 100%; */
    }

    .join-title {
      margin-top: 20px;
      text-align: center;
      font-size: 40px;
    }

    .join-text {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
  }

  @media screen and (max-width: 700px) {
   .join-title{
    font-size: 35px;
    line-height: 50px;
   }

   .join-text{
    font-size: 16px;
    line-height: 30px;
   }
  }
`;
