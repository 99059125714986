import styled from "styled-components";

interface ButtonBoxProps {
  width?: string;
  height?: string;
  bgColor?: string;
  hoverBg?: string;
  textColor?: string;
  border?: string;
  fontWeight?: string;
  fontSize?: string;
  smheight?: string;
  opacity?: boolean;
}

export const ButtonBox = styled.button<ButtonBoxProps>`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "40px"};
  border-radius: 10px;
  padding: 5px 15px;
  background-color: ${({ bgColor }) => bgColor || "var(--billonPrimary)"};
  border: ${({ border }) => border || "1px solid var(--billonPrimary)"};
  color: ${({ textColor }) => textColor || "var(--billonWhite)"};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  outline: none;
  font-size: ${({ fontSize }) => fontSize || "16px"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({opacity})=> opacity && 0.5};

  :hover {
    background-color: ${({ hoverBg }) => hoverBg || "transparent"};
    transition: all 0.5s;
  }

  @media screen and (max-width: 650px) {
    font-weight: 500;
    font-size: 15px;
    height: ${({ smheight }) => smheight};
  }
`;
