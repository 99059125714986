import React, { ReactNode, useState } from "react";
import { BiHide } from "react-icons/bi";
// import { Tooltip } from "antd";
import { InputBox, InputWrapper, IconWrapper } from "./input.styles";
// import { EyeIcon } from "../../../assets/assets";

interface Prop {
  placeholder?: string;
  type?: string;
  onChange?: any;
  children?: ReactNode;
  height?: string;
  border?: string;
  mt?: string;
  label?: string;
  name?: string;
  onBlur?: any;
  value?: any;
  smheight?: string;
  bg?: string;
  secure?: boolean;
  error?: string;
  disabled?: boolean;
  onClick?: any;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const Input: React.FC<Prop> = ({
  placeholder,
  type,
  onChange,
  children,
  height,
  border,
  mt,
  label,
  name,
  onBlur,
  value,
  smheight,
  bg,
  secure,
  error,
  disabled,
  onClick,
  inputRef,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const changeIcon = () => {
    setIsHidden((prop) => !prop);
  };

  return (
    <>
      {label && (
        <label
          style={{
            color: "#000000",
            fontSize: "14px",
            marginBottom: "0px",
            fontWeight: "500",
            fontFamily: "switzer",
          }}
          htmlFor=""
        >
          {label}
        </label>
      )}

      <InputWrapper
        height={height}
        border={error ? "1px solid #E20010" : border}
        mt={mt}
        smheight={smheight}
        bg={error ? "#FFF2F3" : disabled ? "#DCDCDC" : bg}
      >
        <InputBox
          placeholder={placeholder}
          type={
            secure && isHidden
              ? "text"
              : secure && !isHidden
              ? "password"
              : type
          }
          onChange={onChange}
          name={name}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          onClick={onClick}
          ref={inputRef}
        />

        {!secure && <IconWrapper>{children}</IconWrapper>}
      </InputWrapper>
    </>
  );
};

export default Input;
