import styled from "styled-components";

interface InputProps {
  height?: string;
  border?: string;
  mt?: string;
  smheight?: string;
  bg?: string;
}

export const InputBox = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  color: var(--alturaTextColorTwo);
  padding-left: 20px;
  font-size: 16px;
  ::placeholder {
    color: #838383;
  }

  @media screen and (max-width: 650px) {
    ::placeholder {
      color: transparent;
    }
  }
`;

export const InputWrapper = styled.div<InputProps>`
  display: flex;
  height: ${({ height }) => height || "45px"};
  align-items: center;
  width: 100%;
  border: ${({ border }) => border || "1px solid var(--billonBorderColor)"};
  border-radius: 10px;
  margin-top: ${({ mt }) => mt || "0px"};
  background-color: ${({bg})=> bg || "#fff"};

  @media screen and (max-width: 650px) {
    height: ${({ smheight }) => smheight || "50px"};
  }
`;
export const IconWrapper = styled.div`
  width: 40px;
  cursor: pointer;
`;
