import styled, { css } from "styled-components";

interface ModalPropCont {
  width?: string;
  smwidth?: string;
}

interface Wrapper {
  top?: string;
  bottom?: string;
  smTop?: string;
  smBottom?: string;
  left?: string;
  right?: string;
  justify?: string;
  smLeft?: string;
}

interface ModalProp {
  borderTop?: boolean;
}

const HideScrollbar = css`
  /* Hide the scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0em;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
`;

export const SModalOverlay = styled.div`
  background-color: #00000050;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100vw;
  z-index: 10;
`;

export const SModalWrapper = styled.div<Wrapper>`
  display: flex;
  justify-content: ${({ justify }) => justify || "center"};
  align-items: center;
  /* outline: 0; */
  width: 100%;
  left: ${({ left }) => left || "0px"};
  top: ${({ top }) => top || "35%"};
  bottom: ${({ bottom }) => bottom || "0px"};
  right: ${({ right }) => right || "0px"};
  position: fixed;
  z-index: 20;

  @media screen and (max-width: 600px) {
    top: ${({ smTop }) => smTop || "50%"};
    bottom: ${({ smBottom }) => smBottom || "0px"};
    left: ${({ smLeft }) => smLeft || "0px"};
  }
`;

export const SModal = styled.div<ModalPropCont>`
  border-radius: 0.25rem;
  margin: 1.875rem;
  width: ${({ width }) => width || "50%"};
  padding: 20px;
  position: fixed;
  z-index: 30;
  height: 100%;
  max-height: 95vh;
  overflow-y: scroll;
  /* background-color: purple; */
  ${HideScrollbar}

  .header-title {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    float: right;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    width: ${({ smwidth }) => smwidth || "95%"};
    padding: 0%;
  }
`;

export const ModalContent = styled.div<ModalProp>`
  /* background-color: #fff; */
  padding: 10px;
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  border-radius: 20px;
  margin-top: 50px;
  border-top: ${({ borderTop }) =>
    borderTop === true ? "8px solid var(--billonPrimary)" : ""};
`;
