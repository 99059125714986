import styled from "styled-components";
import Checked from "../../assets/success.gif";

export const HeroBox = styled.header`
  max-width: 1500px;
  margin: 0px auto;

  .img-box {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
  }

  .img {
    margin: 0px auto;
    width: 150px;
  }
`;

export const HeroParent = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: auto 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;

  .hero-text {
    color: #161718;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
  }

  @media screen and (max-width: 800px) {
    height: 100vh;
    max-height: 480px;

    .hero-text {
      margin: 30px 5% 0px 5%;
      text-align: center;
      font-size: 17px;
      line-height: 25px;
    }
  }
`;

export const HeroTitle = styled.h1`
  font-size: 75px;
  font-weight: 300;
  line-height: 75px;
  color: #00a3fe;
  text-align: center;
  margin-top: 25px;
  width: 800px;

  .flag {
    position: relative;
  }

  .flag::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: 40%;
    background-size: cover;
    width: 40px;
    height: 30px;
  }

  @media screen and (max-width: 650px) {
    font-size: 45px;
    line-height: 48px;
    text-align: left;
    padding: 0px 5%;
    display: block;
    margin-top: 10px;
    text-align: center;

    .flag::before {
      content: "";
      right: -10px;
      width: 35px;
      height: 20px;
      bottom: 50%;
    }

    span {
      .globe {
        width: 30px;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 870px) {
    width: 100%;
  }
`;

export const HeroSearch = styled.div`
  display: flex;
  grid-column-gap: 15px;
  margin-top: 40px;

  .input {
    width: 330px;
  }

  .btn {
    width: 130px;
  }

  .error {
    color: var(--billonErrorText);
    font-size: 14px;
    margin-top: 5px;
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
    grid-row-gap: 5px;
    width: 100%;
    padding: 0px 5%;

    .input {
      width: 100%;
    }

    .btn {
      width: 100%;
      margin-top: 5px;
    }
  }
`;

export const HeroGallery = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  grid-column-gap: 1.5%;
  margin-top: 30px;
  padding: 50px 0px;

  .gallery {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .phone {
    position: absolute;
    left: 42%;
    max-width: 100%;
    height: auto;
    bottom: 1px;
    z-index: 10;
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const HeroModal = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 60px 5%;
  background-image: url(${Checked});

  .title {
    font-size: 25px;
    color: var(--billonTextPrimary);
    text-align: center;
    line-height: 35px;
  }

  .btn {
    width: 100%;
    max-width: 200px;
    margin-top: 30px;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .check {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .check-text {
    margin-left: 10px;
  }
`;
