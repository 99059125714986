import React, { useState } from "react";
import { db } from "../../utils/firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import {
  HeroBox,
  HeroParent,
  HeroTitle,
  HeroSearch,
  HeroGallery,
  HeroModal,
} from "./hero.styles";
import Input from "../Input/input";
import Button from "../Button/button";
import SearchIcon from "../../assets/search.svg";
import H1 from "../../assets/h1.svg";
import H2 from "../../assets/h2.svg";
import H3 from "../../assets/h3.svg";
import H4 from "../../assets/h4.png";
import Phone from "../../assets/phone.png";
import Logo from "../../assets/logo.png";
import Modal from "../Modal/modal";
import useModal from "../../utils/useModal";
import Checked from "../../assets/success.gif";

const Hero: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { isVisible, toggleModal } = useModal();
  const [subscribe, setSubscribe] = useState<boolean>(false);

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    setError("");
  };

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleSubmit = async () => {
    if (email === "") return false;
    else if (!emailPattern.test(email)) {
      setError("Invalid email");
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        toggleModal();
      }, 2000);
    }
  };

  const handleSendDetails = async () => {
    toggleModal();

    const ref = collection(db, "wait_list");
    let data = {
      email: email,
      created: Timestamp.now(),
      subscribe: subscribe,
    };

    try {
      await addDoc(ref, data);
      setEmail("");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <HeroBox>
      <Modal isVisible={isVisible} hideModal={handleSendDetails}>
        <HeroModal>
          <h2 className="title">Thank you for joining our waitlist</h2>

          <div className="checkbox">
            <div className="check">
              <input
                type="checkbox"
                onChange={(e) => setSubscribe(e.target.checked)}
              />
              <p className="check-text">Subscribe to our newsletter</p>
            </div>

            <div className="btn">
              <Button
                text="Back to Homepage"
                height="50px"
                onClick={handleSendDetails}
              />
            </div>
          </div>
        </HeroModal>
      </Modal>

      <div className="img-box">
        <img src={Logo} alt="logo" className="img" />
      </div>
      <div className="holder">
        <HeroParent>
          <HeroTitle className="text-gradient-hero">
            Convenient and fast bill payment at its peak
          </HeroTitle>

          <p className="hero-text">Join our community to get notified once we launch</p>

          <HeroSearch>
            <>
              <div className="input">
                <Input
                  placeholder="Your email"
                  height="55px"
                  border="0.7px solid #F1F4FF"
                  bg="#F4F4F4"
                  value={email}
                  onChange={handleChange}
                  type="email"
                >
                  {/* <img src={SearchIcon} /> */}
                </Input>

                {error && <p className="error">{error}</p>}
              </div>
            </>

            <div className="btn">
              <Button
                text="Join waitlist"
                height="55px"
                fontWeight="500"
                loading={loading}
                onClick={handleSubmit}
              />
            </div>
          </HeroSearch>
        </HeroParent>
      </div>

      <HeroGallery>
        <img src={H1} className="gallery" alt="gallery" />
        <img src={H2} className="gallery" alt="gallery" />
        <img src={H3} className="gallery" alt="gallery" />
        <img src={H4} className="gallery" alt="gallery" />
        <img src={Phone} alt="phone" className="phone" />
      </HeroGallery>
    </HeroBox>
  );
};

export default Hero;
