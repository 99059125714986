import React, { useState } from "react";
import { FiTwitter } from "react-icons/fi";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiLinkedinLine, RiFacebookLine } from "react-icons/ri";
import { db } from "../../utils/firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import {
  FooterBox,
  FooterGallery,
  FooterItemList,
  FooterParent,
} from "./footer.styles";
import WelfareIcon from "../../assets/welfare.svg";
import Button from "../Button/button";
import { galleryData } from "./footerData";
import Input from "../Input/input";
import SearchIcon from "../../assets/search.svg";
import Logo from "../../assets/logoWhite.png";
import { HeroModal } from "../Hero/hero.styles";
import Modal from "../Modal/modal";
import useModal from "../../utils/useModal";

const Footer: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { isVisible, toggleModal } = useModal();
  const [subscribe, setSubscribe] = useState<boolean>(false);

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    setError("");
  };

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleSubmit = async () => {
    if (email === "") return false;
    else if (!emailPattern.test(email)) {
      setError("Invalid email");
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        toggleModal();
      }, 2000);
    }
  };

  const handleSendDetails = async () => {
    toggleModal();

    const ref = collection(db, "wait_list");
    let data = {
      email: email,
      created: Timestamp.now(),
      subscribe: subscribe,
    };

    try {
      await addDoc(ref, data);
      setEmail("");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FooterBox>
      <Modal isVisible={isVisible} hideModal={handleSendDetails}>
        <HeroModal>
          <h2 className="title">Thank you for joining our waitlist</h2>

          <div className="checkbox">
            <div className="check">
              <input
                type="checkbox"
                onChange={(e) => setSubscribe(e.target.checked)}
              />
              <p className="check-text">Subscribe to our newsletter</p>
            </div>

            <div className="btn">
              <Button
                text="Back to Homepage"
                height="50px"
                onClick={handleSendDetails}
              />
            </div>
          </div>
        </HeroModal>
      </Modal>

      <img src={WelfareIcon} alt="" className="icon" />
      <FooterGallery>
        {galleryData.map((item) => {
          return (
            <img src={item.img} key={item.id} alt="" className="gallery" />
          );
        })}

        <div className="gallery-content">
          <h1 className="g-text">
            Join our community to get notified once we launch
          </h1>

          <div className="box">
            <div className="input">
              <Input
                placeholder="Your email"
                height="55px"
                border="0.7px solid #F1F4FF"
                value={email}
                onChange={handleChange}
                type="email"
              >
                {/* <img src={SearchIcon} /> */}
              </Input>

              {error && <p className="error">{error}</p>}
            </div>

            <div className="g-btn">
              <Button
                text="Join waitlist"
                textColor="#000"
                border="none"
                bgColor="#FDC500"
                height="56px"
                onClick={handleSubmit}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </FooterGallery>

      <FooterParent>
        <FooterItemList>
          <div className="small-logo">
            <img src={Logo} alt="logo" className="logo" />
          </div>

          <p className="list-text">
            Want to connect with us? Find us on social media.
          </p>

          <div className="socials-box-mobile">
            <FiTwitter size={18} color="white" />
            <RiFacebookLine size={20} color="white" />
            <AiOutlineInstagram
              size={18}
              color="white"
              onClick={() =>
                window.open(
                  "https://instagram.com/billon.ng?igshid=OXdqcnY1czN1Njli"
                )
              }
            />
            <RiLinkedinLine size={20} color="white" />
          </div>

          <div className="socials-box">
            {/* <div className="social-item"></div>
            <div className="social-item"></div>
            <div className="social-item"></div> */}
            <FiTwitter size={18} color="white" className="cursor" />
            <RiFacebookLine
              size={20}
              color="white"
              className="cursor"
            />
            <AiOutlineInstagram
              size={18}
              color="white"
              onClick={() =>
                window.open(
                  "https://instagram.com/billon.ng?igshid=OXdqcnY1czN1Njli"
                )
              }
              className="cursor"
            />
            <RiLinkedinLine
              size={20}
              color="white"
              className="cursor"
            />
          </div>
        </FooterItemList>
      </FooterParent>
    </FooterBox>
  );
};

export default Footer;
