import React, { ButtonHTMLAttributes } from "react";
import { ButtonBox } from "./button.styles";

interface Props {
  width?: string;
  height?: string;
  bgColor?: string;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  hoverBg?: string;
  textColor?: string;
  border?: string;
  fontWeight?: string;
  type?: any;
  fontSize?: string;
  smheight?: string;
  loading?: boolean;
  opacity?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  changeBorder?: boolean ;
}

const Button: React.FC<Props> = ({
  width,
  height,
  bgColor,
  text,
  onClick,
  disabled,
  hoverBg,
  textColor,
  border,
  fontWeight,
  fontSize,
  type,
  smheight,
  loading,
  opacity,
  onMouseEnter,
  onMouseLeave,
  changeBorder,
}) => {
  return (
    <ButtonBox
      width={width}
      height={height}
      bgColor={bgColor}
      onClick={onClick}
      disabled={disabled}
      hoverBg={hoverBg}
      textColor={textColor}
      border={border}
      fontWeight={fontWeight}
      type={type}
      fontSize={fontSize}
      smheight={smheight}
      opacity={opacity}
      // changeBorder={changeBorder}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text}

      {loading && (
        <div className="lds-ring">
          <div
          // style={{
          //   border: changeBorder ? "3px solid black" : "",
          //   borderColor:
          //     changeBorder ? "black transparent transparent transparent" : "",
          // }}
          ></div>
          <div
          // style={{
          //   border: changeBorder && "3px solid black",
          //   borderColor:
          //     changeBorder && "black transparent transparent transparent",
          // }}
          ></div>
          <div
          // style={{
          //   border: changeBorder && "3px solid black",
          //   borderColor:
          //     changeBorder && "black transparent transparent transparent",
          // }}
          ></div>
          <div
          // style={{
          //   border: changeBorder && "3px solid black",
          //   borderColor:
          //     changeBorder && "black transparent transparent transparent",
          // }}
          ></div>
        </div>
      )}
    </ButtonBox>
  );
};

export default Button;
