import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import {
  SModalOverlay,
  SModalWrapper,
  SModal,
  ModalContent,
} from "./modal.styles";
import { AiOutlineClose } from "react-icons/ai";

interface ModalProps {
  isVisible?: boolean | null;
  hideModal?: () => void;
  children?: React.ReactNode;
  hasBorder?: boolean;
  width?: string;
  top?: string;
  bottom?: string;
  smTop?: string;
  smBottom?: string;
  smWidth?: string;
  left?: string;
  smLeft?: string;
  right?: string;
  justify?: string;
  hideCloseIcon?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isVisible,
  hideModal,
  children,
  hasBorder,
  width,
  top,
  bottom,
  smTop,
  smBottom,
  smWidth,
  left,
  right,
  justify,
  smLeft,
  hideCloseIcon,
}) => {
  useEffect(() => {
    if (isVisible) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isVisible]);

  return isVisible
    ? createPortal(
        <React.Fragment>
          <SModalOverlay />
          <SModalWrapper
            aria-modal={true}
            aria-hidden={true}
            tabIndex={-1}
            role="dialog"
            top={top}
            bottom={bottom}
            smBottom={smBottom}
            smTop={smTop}
            left={left}
            right={right}
            justify={justify}
            smLeft={smLeft}
          >
            <SModal width={width} smwidth={smWidth}>
              {hideCloseIcon === true ? null : (
                <div className="header-title">
                  <AiOutlineClose
                    size={20}
                    color="#3050B8"
                    onClick={hideModal}
                    className="icon"
                  />
                </div>
              )}
              <ModalContent borderTop={hasBorder}>{children}</ModalContent>
            </SModal>
          </SModalWrapper>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default Modal;
