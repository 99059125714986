import F1 from "../../assets/f1.svg"
import F2 from "../../assets/f2.svg"
import F3 from "../../assets/f3.svg"
import F4 from "../../assets/f4.svg"
import F5 from "../../assets/f5.svg"
import F6 from "../../assets/f6.svg"
import F7 from "../../assets/f7.svg"

type primary = {
  name: string;
  id: number;
  action: () => void;
};

type imgProp = {
    img: string;
    id: number;
}

export const primaryLinks: primary[] = [
  { name: "Home", id: 1, action: () => {} },
  { name: "Safety", id: 2, action: () => {} },
  { name: "Support", id: 3, action: () => {} },
  { name: "Careers", id: 4, action: () => {} },
];

export const secondaryLinks: primary[] = [
    { name: "Team", id: 1, action: () => {} },
    { name: "Contact", id: 2, action: () => {} },
    { name: "Our Story", id: 3, action: () => {} },
    { name: "Media Kit", id: 4, action: () => {} },
  ];

export const galleryData:imgProp[] = [
    {id: 1, img: F1},
    {id: 2, img: F2},
    {id: 3, img: F3},
    {id: 4, img: F4},
    {id: 5, img: F5},
    {id: 6, img: F6},
    {id: 7, img: F7},
]